import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "40%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width:"auto"
    }
  }));
  
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch"
        }
      }
    }
  }));


  const SearchShit = (inf) => {
    return (
      <Search onKeyDown={inf.isMap? function(e) {
        if (e.key === 'Enter') {
          /*console.log('do validate');*/
          if(e.target.value!=='')
          {
            inf.doALL(e.target.value)
          }  
        }
      }:null
      } onChange={inf.isMap?null:function(e) {
        /*console.log('do validate');*/

      inf.doALL(e.target.value)}} /*style={{right: '50px'}}*/>
        <SearchIconWrapper>
          <SearchIcon /*onClick={ inf.toggleDrawer({bottom: true,})}*//>
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={inf.label}
          inputProps={{ "aria-label": "search" }}
        />
      </Search>
    );
  };
  
 export default SearchShit;