import './BusinessDep.css'
import { Divider } from '@mui/material';
import BotonComp from './BotonCompra';



const BusinessDep=({nombreN,telefono,horarioINI,horarioFIN,direccion,paginaWeb})=>{
    return(

    <div class="informations-container">
    
      <h2 class="title">{nombreN}</h2>
      <Divider/>
      <p class = "sub-title" style={{margin:1}}><b>Teléfono: </b>{telefono}</p>
      <p class = "sub-title" style={{margin:1}}><b>Horario: </b>{horarioINI} a {horarioFIN}</p>
      <Divider/>
      <p class = "sub-title" style={{margin:1}}><b>Direccion: </b>{direccion}</p>
      <Divider/>
      <BotonComp paginaWeb={paginaWeb}/>      
    </div>
      
      )
}

export default BusinessDep;