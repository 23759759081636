import { Divider } from "@mui/material"
import React from "react";
import Slider from '@mui/material/Slider';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function valuetext(value) {
    return `${value}CUP`;
  }



const Filtros=({interval,handleChange1,sameQuery})=>{
    
    const marks=[
        {value: 0,label:'0 CUP'},
        {value: 2000,label:'2000 CUP'}
    ]


    return(
        <div >
        <div style={{display:'flex',position:'fixed', zIndex:1}}>
            <div style={{textAlign:'center'}} className="filter-component">
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                Filtros
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{display: 'inline-block'}}>
                        Rango Precio CUP
                        <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        min={0}
                        max={2000}
                        value={interval}
                        onChange={handleChange1}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        marks={marks}
                        color="success"
                        disableSwap
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
               
            </div>
        </div>

        </div>
    )
}

export default Filtros;