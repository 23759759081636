import React, { Component } from "react";
import {BrowserRouter, Router, Switch, Route ,Routes} from "react-router-dom";
import SimpleMap from "./simpleMap";
import ProductDeploy from "./ProductDeploy";
import { Box } from "@mui/material";
import { Global } from "./Global";
import { sliceDir } from "./auxFunctions";


const Routess = (aux)=>{
    var negocios=Global.geolocalitation

    return (
        <Box  >
            <Routes>
            <Route index element={<SimpleMap locations={aux.locations}/>} /> 
            {negocios.map((negocio,index)=>((negocio.paginnaweb==='') ? null :<Route key={index} path={sliceDir(negocio.paginnaweb)} element={<ProductDeploy negocioId={sliceDir(negocio.paginnaweb)} negocioInf={negocio}/>}/> ))}
            <Route>
            </Route>
            </Routes>
        </Box>
    )
}

export default Routess;