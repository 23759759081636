import './BusinessDep.css'
import { sliceDir } from "./auxFunctions";
import StoreIcon from '@mui/icons-material/Store';
import { useState } from 'react';
import { useEffect } from 'react';


const BotonComp=({paginaWeb})=>{
  const [isJumping, setIsJumping] = useState(false);
  
  useEffect(() => {
    const jumpRandomly = () => {
      // Toggle the state to trigger the animation
      setIsJumping(true);

      // Reset the animation after a short delay
      setTimeout(() => {
        setIsJumping(false);
      }, 500); // Adjust this value to match the duration of the jump animation

      // Schedule the next jump after a random interval
      const randomInterval = Math.random() * (5000 - 1000) + 1000; // Random interval between 1 to 5 seconds
      setTimeout(jumpRandomly, randomInterval);
    };

    // Start the jumping animation
    jumpRandomly();
  }, []); // Empty dependency array to run effect only once on component mount


    return(
        <div style={{justifyContent: 'left', display: 'flex'}}>
        <a href={'/'+sliceDir(paginaWeb)} className={'green-button'+(isJumping ? ' jumping-icon' : '')}  target='blank' style={{color:'white'}}><StoreIcon className='store-icon'/></a>
        <b style={{marginTop: '10px', position:'relative',marginLeft:'10px'}}>Ofertas</b>
      </div>   
    )
}
export default BotonComp;