import * as React from 'react';

import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Global } from './Global';

function FloatingB(props) {
    //const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
  
    const handleClick = () => {
        props.toggle(false)
    };
  
    return (
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position:'fixed', bottom: 16, right: 16 }}
        >
        <Fab size="small" aria-label="scroll back to top" id='flDrawer'>
          <KeyboardArrowDownIcon />
        </Fab>
        </Box>
    );
  }
 export function FloatingBSession(props) {
    //const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
  
    const handleClick = () => {
        props.setMarker(Global.geolocalitation)
        props.setLocations(Global.geolocalitation)
    };
  
    return (
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position:'absolute', paddingTop:3, right:50 }}
        >
        <Fab size="small" aria-label="scroll back to top"> 
          <div className='buttond'>Reiniciar</div>
        </Fab>
        </Box>
    );
  }
export default FloatingB;
