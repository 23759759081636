
/*import {
    Marker,
    Popup
  } from "react-leaflet"
  import { Typography } from "@mui/material";
  import Layout from "./Layout";
  import helper from "./myMapHelper";*/

/*export default function turnRenderable(markers){
    var aux=[]
  for(const marker in markers)
  {
    
    var ICON= helper.getIcon( {icon: marker.icon, markerColor: marker.color} );
    aux.push(<Marker icon={ICON}  position = { [marker.longitud,marker.latitud] } key={marker.nombre}>
      <Popup >
        <h3>{marker.nombre}</h3><Typography variant="body2" color="text.secondary">
            Telefono:  {marker.telefonomovil} <br/>
            <Layout/>
          </Typography>
      </Popup>
    </Marker>)
  }
  return aux;
}*/
export default function iconName(icon){
    var index = icon.indexOf('fa-');
    if(index===-1){return null}
    return icon.slice(index+3)
}

export function sliceDir(dir){
    var auxIndex=dir.indexOf('/')+1
    var slicedDir= dir.slice(auxIndex)
    return slicedDir;
}

export function groupByCategory(arr, prop) {
  return arr.reduce((acc, obj) => {
      let key = obj[prop];
      if (!acc[key]) {
          acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
  }, {});
}
export function isValidURL (urlString) {
  var patronURL = new RegExp(
        // valida protocolo
        '^(https?:\\/\\/)?'+
        // valida nombre de dominio
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
        // valida OR direccion ip (v4)
    '((\\d{1,3}\\.){3}\\d{1,3}))'+
        // valida puerto y path
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
        // valida queries
    '(\\?[;&a-z\\d%_.~+=-]*)?'+
        // valida fragment locator
    '(\\#[-a-z\\d_]*)?$','i'); 
  return !!patronURL.test(urlString);
}
export function cargarImagenPorDefecto(e){
  e.target.src= 'imagen-no-encontrada.png';
}
export function percent(total,parte){
  return ((parte/total)*100).toFixed(2);
}

/*{Object.keys(orgProduct).map((key) => 
      (<div><Divider>{key}</Divider><CardsDeploy products={orgProduct[key]} isLoc={false}/></div>))}
    */ //pa despues