import * as React from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import './main.css'
import BotonComp from "./BotonCompra";
import CardMine from './card'

import Layout from "./Layout";
import { Hidden } from "@mui/material";

const CardsDeploy = (inf) => {
  //var Locations = Global.geolocalitation
  /*const [auxLocState,setAuxLocState]=React.useState([])*/
  
    
    /*inf.products.map((product)=>{
      var auxLoc=[]
      Locations.map((loc,index)=>{
     
        if(loc.nombre.includes(product.nombre_negocio)){
        auxLoc.push(loc)
        Locations.splice(index, 1)
      }
      setAuxLocState(auxLocState + auxLoc)
      console.log(auxLoc)
      } 
      )})*/
      //
      //
      
  return (
    <Stack
      spacing={{ xs: 2, sm: 2, md: 4 }}
      direction="row"
      useFlexGap
      alignItems='center'
      justifyContent='center'
      flexWrap="wrap"
      key={inf.categoria}
      
    >
      { !inf.isLoc ? inf.products.map((product,index) => (
        Number(product.cant_prod)>0?<CardMine prod={product} index={index}/>:null
      )): inf.products.map((loacation,index)=>(<Card key={loacation.nombre+'0'+index} sx={{ width:'auto'}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {String(loacation.nombre).split('|')[1]}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Telefono:  {loacation.telefonomovil} <br/>
            <BotonComp paginaWeb={loacation.paginnaweb}/>
          </Typography>
        </CardContent>
        <CardActions>
        </CardActions>
      </Card>))}
    </Stack>
  );
};




export default CardsDeploy;
