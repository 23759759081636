import * as React from "react";
import CardsDeploy from "./cardsDeploy";
import { Box } from "@mui/material";
import Chip from '@mui/material/Chip';
import Header from "./Header";
import { httpF } from "./axiosAux";
import './main.css'
import { Global } from "./Global";
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import StoreIcon from '@mui/icons-material/Store';
import { Outlet, Link } from "react-router-dom";
import { groupByCategory } from "./auxFunctions";
import Typography from "@mui/material/Typography";
import { useViewport } from "./useViewport";
import Filtros from "./FiltrosComponent";



const http = httpF()
const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));


const ProductDeploy = (inf)=> { 
  //console.log('      ----' + inf.negocioId)
  const {width,height} =useViewport()
  const [products,setProducts]=React.useState([]);
  const [otherProducts,setOtherProducts]=React.useState([]);
  const [query, setQuery] = React.useState("");
  const [locations,setLocations]=React.useState(Global.geolocalitation)
  const [orgProduct,setOrgProduct]=React.useState([])
  const [loading,setLoading]=React.useState(true)


  const [interval, setValue1] = React.useState([0, 2000]);
  console.log(interval)
  const minDistance=1;

  const handleChange1 = (
    event,
    newValue,
    activeThumb,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setProducts(Global.Actualproducts.milocalidad);
    setOrgProduct(groupByCategory(Global.Actualproducts.milocalidad,'categoria'))
    setOtherProducts(Global.Actualproducts.otraslocalidad)
    setLocations(Global.geolocalitation)

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], interval[1] - minDistance), interval[1]]);
    } else {
      setValue1([interval[0], Math.max(newValue[1], interval[0] + minDistance)]);
    }

  };
  
  //console.log(products)
  React.useEffect(()=>{{searchProd()}},[query])
  React.useEffect(()=>{{searchProd()}},[interval])
  //React.useEffect(()=>{if(Global.Actualproducts.milocalidad){doALL(query)}},[interval])
  
  React.useEffect(()=>{fetchData()},[])
  
  const fetchData = async () =>{
    await http.get('tienda/getProductoLocalidad/'+btoa(inf.negocioId)).then((response)=>{
      //console.log(response.data)
      Global.Actualproducts=response.data
      
      setProducts(response.data.milocalidad);
      setOrgProduct(groupByCategory(response.data.milocalidad,'categoria'))
      setOtherProducts(response.data.otraslocalidad);
      setLoading(false)
    })
  }
  
  const doALL=(newQuery)=>{
    var realNewQuery=String(newQuery).replace(/\s+/g, ' ').trim()
      
      
      setProducts(Global.Actualproducts.milocalidad);
      setOrgProduct(groupByCategory(Global.Actualproducts.milocalidad,'categoria'))
      setOtherProducts(Global.Actualproducts.otraslocalidad)
      setLocations(Global.geolocalitation)
      setQuery(realNewQuery);
    
  }
  const searchProd=()=>{
    
    
    var aux=[];
    
    var aux2=[]
    var aux3=[]
    products.map((product)=>{if((product.descripcion.toLowerCase()).includes(query.toLowerCase())      
      &&

      Number(String(product.precio))>interval[0]
      &&
      Number(String(product.precio))<interval[1]
      )
      {

        aux.push(product)
      }
    })
    
    otherProducts.map((product)=>{if((product.descripcion.toLowerCase()).includes(query.toLowerCase()))
      {
        aux2.push(product)
        locations.map((loc)=>{if(loc.nombre.includes(product.nombre_negocio))
          {
            if(!aux3.includes(loc))
            {
              aux3.push(loc)
            }
          }})
        }
        
      })
      setLocations(aux3)  
      setOtherProducts(aux2)
      setProducts(aux); 
      setOrgProduct(groupByCategory(aux,'categoria'));
    }
    
    return(
      
      <Box  >
        
      <Header doALL={doALL} isMap={false} label='Buscar productos...' negocio={inf.negocioInf}/>
      {!loading?<Filtros interval={interval} handleChange1={handleChange1} sameQuery={query}/>:null}
      <Box component="main" sx={{ p: 3 }}> 
      {loading?<div className="lds-dual-ring"></div>:null}
      {products.length===0&&(interval[0]!=0||interval[1]!=2000||query!='')?<Typography sx={{margin:'50px',textAlign:'center', fontSize:'50px'}}>No se encotraron productos</Typography>:Object.keys(orgProduct).map((key) => 
      (<div key={key}><Divider sx={{marginBottom: 2, marginTop:3}}>{key!=='undefined'?key:'PRODCUTOS VARIOS'}</Divider><CardsDeploy products={orgProduct[key]} isLoc={false} categoria={key}/></div>))}
      
      </Box> 
      {(query!==''&&otherProducts.length>0 && otherProducts.length<Global.Actualproducts.milocalidad.length) ?(<div><Divider><Chip label="Otras Localidades" /></Divider><Box component="main" sx={{ p: 3 }} ><CardsDeploy products={locations} isLoc={true}/></Box></div>): null}
      
      {!loading?<footer style={{width: width, bottom: 0 }}>
    <div className="container informacion " id="contacto">
      <div className="row">
        <div className="col-lg-5 p-3">
          <h3 style={{textAlign:'center'}}>Informacion Negocio</h3>
          <p><StoreIcon/>{String(inf.negocioInf.nombre).split('|')[1]}</p>
          <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
            </svg>{inf.negocioInf.direccion}</p>
          <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>
            </svg>{inf.negocioInf.telefonomovil}</p>
        </div>
        <div className="col-lg-3 col-md-5 p-3">
          <h3>Visita los negocios que ya tienen Saldo GRIN</h3>

          <Link to='/' target="blank"><button type="button" className="btn mi-boton-green mi-boton" data-bs-toggle="modal" data-bs-target="#exampleModal" >
            Ver Mapa
          </button></Link>
          
          
        </div>
        
      </div>
    </div>
    <div className="text-center p-3" style={{backgroundColor: 'black', color: '#ffffff91'} }>
    <p style={{fontSize:'16px'}}>© 2023 Copyright:
      <a className="text-white" href="https://sistema.grin.cu/">Sistema GRIN</a>
    </p>
  </div>
  </footer>:null}
  
      </Box>)
}

export default ProductDeploy;

/*{(products.length>0 && products.length<Global.Actualproducts.milocalidad.length)? <Box sx={{ display: 'flex'}}><Divider>OTRAS LOCALIDADES</Divider> <CardsDeploy products={otherProducts}/></Box>: null }*/