import { LocationSearching } from '@mui/icons-material';
import React, { createContext, useState } from 'react';

const VariablesContext = createContext([]);

const VariablesProvider =({children})=>{
    
    const defaultLocation={
        lat: 23.113592,
        long: -82.366592
    }
    
    const localStorageLocation=(localStorage.getItem('location-latitude')!==null&&localStorage.getItem('location-longitude')!==null? {lat:localStorage.getItem('location-latitude'),long:localStorage.getItem('location-longitude')}:null)
    const [location,setLocation]= useState((localStorageLocation||defaultLocation))

    console.log(location)
    const changeLocation=(location)=>{
        setLocation(location)
        updateLocalStorage(location)
    }

    const updateLocalStorage = (newState)=>{
        localStorage.removeItem('location-latitude')
        localStorage.setItem('location-latitude',newState.lat)
        localStorage.removeItem('location-longitude')
        localStorage.setItem('location-longitude',newState.long)
    }

    const cleanStorage=()=>{
        localStorage.removeItem('location-latitude')
        localStorage.removeItem('location-longitude')
    }

    let context={
        changeLocation,
        location,
        cleanStorage
    }
    return (
        <VariablesContext.Provider value={context}>
          {children}
        </VariablesContext.Provider>
      );
}

export {VariablesContext, VariablesProvider}