import React, { useRef } from "react";
//import { MapContainer, TileLayer,Popup ,useMap} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet";
import { Global } from "./Global";
import { Container, Drawer, Icon } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack"
import Header from "./Header";
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css'
import 'leaflet.awesome-markers'
import 'font-awesome/css/font-awesome.min.css'
import 'font-awesome/css/font-awesome.css'
import 'font-awesome/fonts/fontawesome-webfont.svg'
import FloatingB from "./FloatingButton";
import { FloatingBSession } from "./FloatingButton";
import { useViewport } from "./useViewport";
import { mobileBrake } from "./Constants";
import BotonComp from "./BotonCompra";

import BusinessDep from "./BusinessDeploy";

import Box from "@mui/material/Box";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup
} from "react-leaflet"
import helper from './myMapHelper'
import iconName from "./auxFunctions";
import { VariablesContext } from "./Context";
import { useContext } from "react";



const SimpleMap = () => {
  const mapRef = useRef(null);
  const {location, changeLocation,cleanStorage}=useContext(VariablesContext);
  console.log(location.lat+'  '+location.long)
  const [locations,setLocations] = React.useState(Global.geolocalitation)
  const { width, height} = useViewport()

  const [markers,setMarkers]= React.useState(Global.geolocalitation);

  //const [markersDeploy,setMarkersDeploy]=React.useState(turnRenderable(markers))
  
  const [query, setQuery] = React.useState("");

  React.useEffect(()=>{if(query!=''){var cant = searchLoc(); setDrawer({bottom: true})}},[query])
  

  

  
  
  const [drawer, setDrawer] = React.useState({
    bottom: false,
  });
  
  const doALL=(newQuery)=>{
    setLocations(Global.geolocalitation);

    setQuery(newQuery); 
  }


  const searchLoc = () =>{
    if(query=='')
    {return }
    var aux=[];
    locations.map((location)=>{if((location.nombre.toLowerCase()).includes(query.toLowerCase()))
    {
      aux.push(location)
    }
    })
    if(aux.length===0)
    {
      setMarkers(Global.geolocalitation)
    }
    else
    {
      setMarkers(aux)
    }
    setLocations(aux);  
    
    
    return
  }

  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawer({ ...drawer, [anchor]: open });
  };

  //var leafIcon = helper.getIcon( {ic, markerColor: 'red'} );
    

  
  return (
    <Box sx={{ display: 'flex' }}>  
    
    {/*doALL={doALL} toggleDrawer={setDrawer} search={searchLoc} set={setLocations} */}
    <Header doALL={doALL} isMap={true} label='Buscar tienda...'/>
      
      
    
    <MapContainer
      center={[location.lat, location.long]}
      zoom={localStorage.getItem('location-latitude')?20:13}
      ref={mapRef}
      style={{ height: String((width<mobileBrake)?height-115:height-90)+'px', width: "100vw" }}
      whenReady={()=>{cleanStorage()}}
    >
      {locations.length!=Global.geolocalitation.length && locations.length!=0 ? <FloatingBSession setMarker={setMarkers} setLocations={setLocations}/> :null}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      
      {markers.map((marker)=>
      (
      <Marker icon={helper.getIcon({icon: iconName(marker.icon) , markerColor: marker.color})}  position = { [marker.longitud,marker.latitud] } key={marker.longitud}>
        
        <Popup >
        <BusinessDep nombreN={String(marker.nombre).split('|')[1]}
        telefono={marker.telefonomovil} horarioINI={marker.hora_ini} horarioFIN={marker.hora_fin} 
        direccion={marker.direccion} paginaWeb={marker.paginnaweb}/>

        </Popup>
      </Marker>
      ))}
    </MapContainer>
    <Drawer
            anchor='bottom'
            open={drawer['bottom']}
            onClose={toggleDrawer('bottom', false)}
            className="scroll"
            
          >
            <Box
              sx={{ width:'auto' }}
              role="presentation"
              height={locations.length === 0 ? 100: {}}
                /*onClick={toggleDrawer('bottom', false)}*/
              onKeyDown={toggleDrawer('bottom', false)}>

               {locations.length === 0 ? <Typography variant="h5" color="text.secondary" style={{ textAlign: 'center'}}> 
              No Results
            </Typography>  
            
            : <Stack
              spacing={{ xs: 1, sm: 2, md: 4 }}
              direction="row"
              useFlexGap
              justifyContent='center'
              flexWrap="wrap"
            >
              {locations.map((loacation)=>(<Card key={loacation.nombre} sx={{ maxWidth: 250 , marginTop: '5px'}}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {String(loacation.nombre).split('|')[1]}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Telefono:  {loacation.telefonomovil} <br/>
              <BotonComp paginaWeb={loacation.paginnaweb}/>
            </Typography>
          </CardContent>
          <CardActions>
          </CardActions>
        </Card>))}
            </Stack>}
                <FloatingB toggle={setDrawer}/>
              </Box>
          </Drawer>
    </Box>
    
  );
};
/*{markers.length===0 ? ()=>{}: ()=>{{markers.map((marker)=>(
  <Marker position={[marker.latitud,marker.longitud]}>
  <Popup>
    A pretty CSS3 popup. <br /> Easily customizable.
  </Popup>
  </Marker>))}}}*/

export default SimpleMap;
