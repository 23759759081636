import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainApp from './App';
import reportWebVitals from './reportWebVitals';
import { Global } from "./Global";
import { httpF } from './axiosAux';
import { AxiosHeaders } from 'axios';
import { loadRecaptcha } from './loadRecaptcha';

axios.defaults.headers.post["customercode"] = "negocio";

const root = ReactDOM.createRoot(document.getElementById('root'));
const http= httpF();
const grecaptcha = await loadRecaptcha('6LeaOl8nAAAAAOE5CiJVVmCpE7UDXIlDNhSGw1rk');
/*let config = {
  headers: {
    "Content-Type": "application/json",
    "customercode": "negocio"
  }
};*/

grecaptcha.ready(()=>{grecaptcha.execute('6LeaOl8nAAAAAOE5CiJVVmCpE7UDXIlDNhSGw1rk', { action: 'submit' }).then(token=>{
  http.defaults.headers.common['recaptchaToken']=token;

  http
  .post("generate-token")
  .then((response) => {
    if (response.status === 200) {
      
      window.sessionStorage.setItem('token',response.data.token)
      Global.token = response.data.token
      
      //console.log(Global.token)
      http.get("tienda/getGeolocalitation")
        .then(response => {
          Global.geolocalitation = response.data;
          //console.log(Global.geolocalitation)
          
          root.render(
            
          <React.StrictMode>
          <MainApp />
          </React.StrictMode>
);
        })
        .catch(error => {
          console.error("Error al obtener datos de ubicación: ", error);
      });
      
    }
  })
  .catch((error) => {
    
    console.log(error)

    sessionStorage.clear();
  });})
}
)
  



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

