import axios from 'axios';
import { Global } from './Global';

let URL = 'https://saldogrin.com/api/';

if (process.env.NODE_ENV === 'development') {
  URL = 'https://saldo.grin.cu/api/';
} 

const token = window.sessionStorage.getItem('token'); 
const defaultHeaders={};
//console.log(token + '     aux----')
if (token) {
  defaultHeaders['Authorization'] = `Bearer ${token}`;
}

const http = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
    "customercode": "negocio"
  }
});

  
// Add a request interceptor
http.interceptors.request.use((config)=> {
  if (Global.token) {
    try {
        
        defaultHeaders['Authorization'] = `Bearer ${Global.token}`
    
        config.headers = defaultHeaders;            
    } catch (error) {
        console.error('Error al decodificar el token:', error);
    }
  }
  if (window.grecaptcha) {
    return window.grecaptcha.execute('6LeaOl8nAAAAAOE5CiJVVmCpE7UDXIlDNhSGw1rk', { action: 'submit' })
      .then((recaptchaToken) => {
        
        
        config.headers['recaptchaToken'] = recaptchaToken ;
        return config;
      })
      .catch((error) => {
        console.error('Error al generar el token de reCAPTCHA:', error);
        return Promise.reject(error);
      });
  }
  return config;
}, (error)=> {
// Do something with request error

return Promise.reject(error);
});

// Add a response interceptor
http.interceptors.response.use( (response)=> {
 
// Do something with response data


return response;
}, (error)=> {
  // Do something with response error
  return Promise.reject(error);
});


export function httpF() {
  return http;
}
export function UrlF(){
  return URL;
}
