import Backdrop from '@mui/material/Backdrop';
import ImageSlider from './ProductImagesDep';
import React from 'react';
import ImgsViewer from 'react-images-viewer'


export default function BackdropImageSlider({slides,isOpen,index,setIsOpen,setCurrImg,currImg}) {

    

    return (
      <div key={index}>
        <ImgsViewer
        backdropCloseable={true}
        imgs={slides}
        currImg={currImg}
        showThumbnails={true}
        isOpen={isOpen}
        onClickPrev={e => setCurrImg(currImg - 1)}
        onClickNext={e => setCurrImg(currImg + 1)}
        onClickThumbnail={index => setCurrImg(index)}
        onClose={e => setIsOpen(false)}

      />
      </div>
    )
}